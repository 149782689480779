
































































































import {
    Component,
    Vue,
    Watch
} from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import {
    Toast
} from "vant";
import Mixin from "@/js/mixins";
import {
    href
} from "../../js/api/route";

@Component({
    components: {
        MyForm
    },
    mixins: [Mixin]
})
export default class PayPay extends Vue {
    public showKeyboard = false;
    public password = "";
    public user_id = "";
    public order_id = ""; // 订单id
    public payList = [
        //   {icon:'icon-yue',title:'余额支付',detail:'使用账户积分支付',color:'#217cff'},
        {
            pay_id: 0,
            // pay_img: require("@/assets/shangchuan.png"),
            pay_img: 'http://myxls.oss-cn-hangzhou.aliyuncs.com/images/2020/11/11/image_160507791155979710.png',
            pay_name: "线下支付",
            pay_desc: "已经支付,上传支付凭证"
        }
    ];
    public payActive = 0;
    public info: any = null;
    public title = "";
    public payId = 0;
    public isShowWay: boolean = false; //是否显示选择支付方式
    public changeIsEnough: boolean = false; //当前选择的账号是否足够支付
    public changePayList: any = []; //当前选择支付方式列表
    public allPayList: any = []; //全部支付方式列表
    public changePayNumber: any = 0; //当前选择的总金额

    // :to="'/pay?user_id='+user_id+'&order_id='+item.order_id"
    // 选择支付方式
    @Watch("password")
    getpassword(to, from) {
        if (to.length == 6) {
            console.log("进行验证");
            let _this = this;
            let relevance_pay_user_id: any = []
            this.changePayList.map((item, index) => {
                relevance_pay_user_id.push(item.user_id)
            })
            this.$api.request({
                url: "balance/pay/pay-order",
                data: {
                    user_id: this.user_id,
                    order_id: this.order_id,
                    pay_id: this.payId,
                    password: this.password,
                    relevance_pay_user_id
                },
                success(res) {
                    _this.showKeyboard = false;
                    _this.$toast("支付成功！");
                    setTimeout(time => {
                        href("/");
                    }, 1000);
                },
                fail(err) {
					console.log(err);
                     _this.showKeyboard = false;
                    _this.password = "";
					_this.$toast(err.message)
					
                }
            });
        }
    }
    @Watch("changePayList")
    getchangePayList(to, from) {
        let number = 0
        this.allPayList.map((ite, ind) => { //先遍历全部列表
            this.allPayList[ind].changeIndex = 0
        })
        to.map((item, index) => { //遍历选中列表
            this.allPayList.map((ite, ind) => { //遍历全部列表
                if (item.user_name == ite.user_name) {
                    this.allPayList[ind].changeIndex = index + 1
                }
            })

            //计算选中总金额
            number += Number(item.account_money)
        })
        this.changePayNumber = number.toFixed(2)
        if (Number(this.changePayNumber) >= Number(this.info.order.no_pay_amount)) {
            this.changeIsEnough = true
        }
    }
    confirmPayWay() { //确认选择的支付方式
        if (this.changeIsEnough) {
            this.showKeyboard = true
            this.isShowWay = false;
        } else {
            this.$toast('总金额不够用来支付')
        }
    }
    changePayWay(i) { //选择支付门店
        let isHave = false //之前是不是有
        let haveIndex = 0
        this.changePayList.map((item, index) => { //先遍历看之前的有没有
            if (item.user_name == i.user_name) {
                isHave = true
                haveIndex = index
            }
        })
        if (isHave && this.changePayList.length != 1) { //如果之前有并且当前选中不止一条,就删掉
            this.changePayList.splice(haveIndex, 1)
        } else if (!isHave && Number(i.account_money) != 0) {
            this.changePayList.push(i)
        }
    }
    _changePay(index, id) {
        this.payActive = index;
        this.payId = id;
    }
    // 选择支付方式
    showWay() {
        console.log('xuanze')
        this.showKeyboard = false
        this.isShowWay = true

    }
    //确认支付
    _subPay() {
        if (this.payId == 0) {
            href("/pay?user_id=" + this.user_id + "&order_id=" + this.order_id);
        } else {
            this.showKeyboard = true;
            this.password = "";
        }
    }
    onDelete() {
        this.password = this.password.slice(0, this.password.length - 1);
    }
    onInput(key) {
        this.password = (this.password + key).slice(0, 6);
    }
    init() {
        let _this = this;
        this.payList = [
            //   {icon:'icon-yue',title:'余额支付',detail:'使用账户积分支付',color:'#217cff'},
            {
                pay_id: 0,
                // pay_img: require("@/assets/shangchuan.png"),
                pay_img: 'http://myxls.oss-cn-hangzhou.aliyuncs.com/images/2020/11/11/image_160507791155979710.png',
                pay_name: "线下支付",
                pay_desc: "已经支付,上传支付凭证"
            }
        ];
        this.title = "";
        this.password = "";
        this.user_id = String(this.$route.query.user_id || "");
        this.order_id = String(this.$route.query.order_id || "");
        this.$api.request({
            url: "balance/pay/order",
            data: {
                user_id: this.user_id,
                order_id: this.order_id
            },
            success(res) {
                _this.info = res.data;
                _this.allPayList = res.data.account_list
                _this.allPayList.map((item, index) => {
                    _this.allPayList[index].changeIndex = 0
                    if (item.default == 1) {
                        _this.changePayList = [item]
                    }
                })
                _this.info.payment.map((item, index) => {
                    _this.payList.push(item);
                });
                _this.payList.map(item => {
                    if (item.pay_id == 5) {
                        item.pay_desc = `使用账户积分进行付款，当前积分余额${res.data.user_money}`;
                    }
                });
                // _this.info.order.order_mation[0].goods_list.map(item => {
                //   _this.title = ` ${_this.title}${item.goods_name}*${item.goods_number}`;
                // });
                _this.info.order.order_mation.map(ite => {
                    ite.goods_list.map(item => {
                        _this.title = ` ${_this.title} ${item.goods_name}*${item.goods_number}`;
                    });
                });
            }
        });
    }
}
// 1296db
